export default function projectenum() {
const projectids =[
'grccompliancemanager',
'al2migration',
'djsautomationforacems',
's3costoptimisationproject',
'projectsandwihch',
'patchmanagershephered',
'grccomplianceaudit',
'addresssasrisk',
'grccompliancemanager',
'pipelinefailure',
'ospatchingproject',
'customerticket',
'grctickethandling',
'codereviews',
'providingkttose1',
]
return projectids
}