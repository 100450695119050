import amazonlogo from "../assets/homepage/AmazonLogo.svg";
export default function journeystatic() {
  const jobdetails = [
    {
      companylogo: amazonlogo,
      description:
        "From October 2021 I had started my career as Application engineer in amazon music team",
    },
    {
      companylogo: amazonlogo,
      description:
        "On April 2023 , I got promoted as Application engineer - II and owned compliance goal (software security assesment)  in amazon music team",
    },

    // {
    //     companylogo : "",
    //     description : ""
    // }
  ];
  return jobdetails;
}
