// import Csharp from '../../assets/homepage/skills/csharp.svg';
import Flutter from "../assets/homepage/skills/flutter.svg";
import Java from "../assets/homepage/skills/java.svg";
import NodeJs from "../assets/homepage/skills/nodejs.svg";
import Angular from "../assets/homepage/skills/angular.svg";
import Python from "../assets/homepage/skills/python.svg";
import ReactJs from "../assets/homepage/skills/reactjs.svg";
import Spark from "../assets/homepage/skills/spark.svg";
import Scikit from "../assets/homepage/skills/scikitlearn.svg";
import pytorch from "../assets/homepage/skills/pytorch.svg"
import pandas from "../assets/homepage/skills/Pandas.svg"
import etl from "../assets/homepage/skills/etl.svg"
import apacheairflow from "../assets/homepage/skills/Apache Airflow.svg"
import genai from "../assets/homepage/skills/artificial-intelligence-ai-icon.svg"
export default function skillsarray() {
  const logos = [
    "br",
    Scikit,
    pytorch,
    pandas,
    etl,
    apacheairflow,
    genai,
    "br",
    Spark,
    Python,
    Angular,
    Java,
    Flutter,
    NodeJs,
    "br",
    ReactJs,
  ];
  return logos;
}

// color code #6600FF
//size 1024 X 1024

// text generator : https://maketext.io/
