import a from '../assets/projects/fontsimage/A.svg'
import b from '../assets/projects/fontsimage/B.svg'
import c from '../assets/projects/fontsimage/C.svg'
import d from '../assets/projects/fontsimage/D.svg'
import e from '../assets/projects/fontsimage/E.svg'
import f from '../assets/projects/fontsimage/F.svg'
import g from '../assets/projects/fontsimage/G.svg'
import h from '../assets/projects/fontsimage/H.svg'
import i from '../assets/projects/fontsimage/I.svg'
import j from '../assets/projects/fontsimage/J.svg'
import k from '../assets/projects/fontsimage/K.svg'
import l from '../assets/projects/fontsimage/L.svg'
import m from '../assets/projects/fontsimage/M.svg'
import n from '../assets/projects/fontsimage/N.svg'
import o from '../assets/projects/fontsimage/O.svg'
import p from '../assets/projects/fontsimage/P.svg'
import q from '../assets/projects/fontsimage/Q.svg'
import r from '../assets/projects/fontsimage/R.svg'
import s from '../assets/projects/fontsimage/S.svg'
import t from '../assets/projects/fontsimage/T.svg'
import u from '../assets/projects/fontsimage/U.svg'
import v from '../assets/projects/fontsimage/V.svg'
import w from '../assets/projects/fontsimage/W.svg'
import x from '../assets/projects/fontsimage/X.svg'
import y from '../assets/projects/fontsimage/Y.svg'
import z from '../assets/projects/fontsimage/Z.svg'
export default function TopProjectarray() {
    const topprojects_array = [

   
        {
            projectid : "1"
        },
        
        {
        projectid : "00",
        projectTitle : "GRC Compliance Manager",
        projectLogo : g,
        projectLink : "/projectsdetail?name=grccompliancemanager"
    },
    {
        projectid : "1"
    },
    
    {
    projectid : "01",
    projectTitle : "OS Patching",
    projectLogo : o,
    projectLink : "/projectsdetail?name=ospatchingproject"
},
{
    projectid : "1"
},

{
projectid : "02",
projectTitle : "Customer Tickets",
projectLogo : c,
projectLink : "/projectsdetail?name=customerticket"
},
{
    projectid : "r"
},
{
    projectid : "1"
},

{
projectid : "03",
projectTitle : "Project Sandswitch",
projectLogo : p,
projectLink : "/projectsdetail?name=projectsandwihch"
},
{
    projectid : "1"
},
{
    projectid : "04",
    projectTitle : "AL2 Migration",
    projectLogo : a,
    projectLink : "/projectsdetail?name=al2migration"
    },
    {
        projectid : "1"
    },
    {
        projectid : "05",
        projectTitle : "S3 Cost Optimisation",
        projectLogo : s,
        projectLink : "/projectsdetail?name=s3costoptimisationproject"
        },

]

    return topprojects_array;
}

